import React from 'react';
import { BarChart3, Users, DollarSign, Target, ArrowRight } from 'lucide-react';
import { cn } from '../../lib/utils';

const templates = [
  {
    id: 'campaign-performance',
    name: 'Campaign Performance Overview',
    description: 'Analyze campaign metrics, ROI, and performance trends across all channels',
    icon: BarChart3,
    color: 'bg-blue-500',
  },
  {
    id: 'conversion-attribution',
    name: 'Conversion Attribution',
    description: 'Track conversion paths and understand which channels drive the most value',
    icon: Target,
    color: 'bg-green-500',
  },
  {
    id: 'audience-insights',
    name: 'Audience Insights',
    description: 'Understand your audience demographics, interests, and behavior',
    icon: Users,
    color: 'bg-purple-500',
  },
  {
    id: 'budget-analysis',
    name: 'Budget Analysis',
    description: 'Track spending, ROI, and budget allocation across campaigns',
    icon: DollarSign,
    color: 'bg-orange-500',
  },
];

interface ReportTemplatesProps {
  onSelectReport: (reportId: string) => void;
}

export default function ReportTemplates({ onSelectReport }: ReportTemplatesProps) {
  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 mt-6">
      {templates.map((template) => (
        <div
          key={template.id}
          className="relative group bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow"
        >
          <div className="flex items-center">
            <div className={cn(
              'flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-lg',
              template.color,
            )}>
              <template.icon className="h-6 w-6 text-white" />
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-medium text-gray-900">
                {template.name}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {template.description}
              </p>
            </div>
          </div>
          <button
            onClick={() => onSelectReport(template.id)}
            className="mt-4 inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-500"
          >
            View Report
            <ArrowRight className="ml-1 h-4 w-4" />
          </button>
        </div>
      ))}
    </div>
  );
}