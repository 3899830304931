import { Settings, UserPlus, UserMinus, Edit, Plus, RefreshCw } from 'lucide-react';
import { ActivityLog } from '../types/company';

export function getActivityIcon(action: string) {
  switch (action) {
    case 'campaign_created':
      return Plus;
    case 'campaign_updated':
      return Edit;
    case 'settings_updated':
      return Settings;
    case 'member_added':
      return UserPlus;
    case 'member_removed':
      return UserMinus;
    case 'member_updated':
      return RefreshCw;
    default:
      return Settings;
  }
}

export function getActivityDescription(activity: ActivityLog): string {
  switch (activity.action) {
    case 'campaign_created':
      return `Created campaign "${activity.details.name}"`;
    case 'campaign_updated':
      return `Updated campaign "${activity.details.name}"`;
    case 'settings_updated':
      return 'Updated company settings';
    case 'member_added':
      return `Added team member ${activity.details.email}`;
    case 'member_removed':
      return `Removed team member ${activity.details.email}`;
    case 'member_updated':
      return `Updated team member ${activity.details.email}`;
    default:
      return 'Performed an action';
  }
}