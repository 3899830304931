import React, { useState, useEffect } from 'react';
import { UserPlus } from 'lucide-react';
import { TeamMember } from '../../types/company';
import { useAuth } from '../../contexts/AuthContext';
import { queryByIndex } from '../../lib/db';
import InviteTeamMemberModal from '../../components/team/InviteTeamMemberModal';
import TeamMemberTable from '../../components/team/TeamMemberTable';

export default function TeamManagementPage() {
  const { user } = useAuth();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showInviteModal, setShowInviteModal] = useState(false);

  useEffect(() => {
    loadTeamMembers();
  }, [user]);

  const loadTeamMembers = async () => {
    if (!user?.organizationId) return;
    
    try {
      const members = await queryByIndex<TeamMember>(
        'teamMembers',
        'by-company',
        user.organizationId
      );
      setTeamMembers(members);
    } catch (error) {
      console.error('Error loading team members:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInviteSent = async (newMember: TeamMember) => {
    setTeamMembers(prev => [...prev, newMember]);
    setShowInviteModal(false);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Team Management
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            Manage your team members and their access levels
          </p>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            type="button"
            onClick={() => setShowInviteModal(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <UserPlus className="h-4 w-4 mr-2" />
            Invite Team Member
          </button>
        </div>
      </div>

      <div className="mt-8">
        <TeamMemberTable
          members={teamMembers}
          isLoading={isLoading}
          onMemberUpdate={loadTeamMembers}
        />
      </div>

      {showInviteModal && (
        <InviteTeamMemberModal
          onClose={() => setShowInviteModal(false)}
          onInviteSent={handleInviteSent}
        />
      )}
    </div>
  );
}