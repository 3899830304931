import { useQuery } from '@tanstack/react-query';
import { Campaign } from '../types/campaigns';
import { DateRange } from '../types/common';

interface UseCampaignsOptions {
  platforms?: string[];
  statuses?: string[];
  dateRange?: DateRange;
  search?: string;
}

export function useCampaigns(options: UseCampaignsOptions = {}) {
  return useQuery({
    queryKey: ['campaigns', options],
    queryFn: async () => {
      // TODO: Replace with actual API call
      const mockCampaigns: Campaign[] = [
        {
          id: '1',
          name: 'Summer Sale 2024',
          platform: 'google',
          status: 'active',
          budget: 5000,
          impressions: 150000,
          clicks: 7500,
          ctr: 5,
          conversions: 250,
          cpc: 2.5,
          roas: 3.2,
          startDate: '2024-06-01',
          endDate: '2024-08-31',
        },
        {
          id: '2',
          name: 'Brand Awareness Q1',
          platform: 'meta',
          status: 'paused',
          budget: 3000,
          impressions: 80000,
          clicks: 4000,
          ctr: 5,
          conversions: 120,
          cpc: 1.8,
          roas: 2.5,
          startDate: '2024-01-01',
          endDate: '2024-03-31',
        },
      ];

      return mockCampaigns;
    },
  });
}