import React from 'react';
import { LogIn, UserPlus } from 'lucide-react';
import { cn } from '../../lib/utils';

interface AuthTabsProps {
  activeTab: 'login' | 'register';
  onTabChange: (tab: 'login' | 'register') => void;
}

export default function AuthTabs({ activeTab, onTabChange }: AuthTabsProps) {
  return (
    <div className="flex space-x-4 mb-8">
      <button
        onClick={() => onTabChange('login')}
        className={cn(
          'flex items-center px-4 py-2 rounded-md transition-colors',
          activeTab === 'login'
            ? 'bg-indigo-600 text-white'
            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
        )}
      >
        <LogIn className="h-4 w-4 mr-2" />
        Sign In
      </button>
      <button
        onClick={() => onTabChange('register')}
        className={cn(
          'flex items-center px-4 py-2 rounded-md transition-colors',
          activeTab === 'register'
            ? 'bg-indigo-600 text-white'
            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
        )}
      >
        <UserPlus className="h-4 w-4 mr-2" />
        Register
      </button>
    </div>
  );
}