import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardLayout from '../components/layout/DashboardLayout';
import DashboardHome from '../components/dashboard/DashboardHome';
import Integrations from './Integrations';
import CampaignListPage from './campaigns/CampaignListPage';
import SettingsPage from './Settings';
import NotificationInboxPage from './notifications/NotificationInboxPage';
import ReportsPage from './reports/ReportsPage';
import TeamManagementPage from './team/TeamManagementPage';
import ActivityLogPage from './activity/ActivityLogPage';

export default function Dashboard() {
  return (
    <DashboardLayout>
      <Routes>
        <Route path="/" element={<DashboardHome />} />
        <Route path="/campaigns" element={<CampaignListPage />} />
        <Route path="/reports" element={<ReportsPage />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="/settings/*" element={<SettingsPage />} />
        <Route path="/notifications" element={<NotificationInboxPage />} />
        <Route path="/team" element={<TeamManagementPage />} />
        <Route path="/activity" element={<ActivityLogPage />} />
      </Routes>
    </DashboardLayout>
  );
}