import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlusCircle, AlertCircle, CheckCircle } from 'lucide-react';
import { AdPlatformType } from '../types/adPlatforms';
import { GoogleAdsIntegration } from '../services/integrations/googleAdsIntegration';
import { MetaAdsIntegration } from '../services/integrations/metaAdsIntegration';
import { TikTokAdsIntegration } from '../services/integrations/tiktokAdsIntegration';
import { OutbrainIntegration } from '../services/integrations/outbrainIntegration';
import { TaboolaIntegration } from '../services/integrations/taboolaIntegration';

const platforms: { id: AdPlatformType; name: string; logo: string }[] = [
  {
    id: 'google',
    name: 'Google Ads',
    logo: 'https://www.gstatic.com/images/branding/product/2x/ads_48dp.png',
  },
  {
    id: 'meta',
    name: 'Meta Ads',
    logo: 'https://www.facebook.com/images/fb_icon_325x325.png',
  },
  {
    id: 'tiktok',
    name: 'TikTok Ads',
    logo: 'https://sf16-scmcdn-va.ibytedtos.com/goofy/tiktok/web/node/_next/static/images/logo-black-e3e700a8c7164bf2f397bd52a6c86e7c.svg',
  },
  {
    id: 'outbrain',
    name: 'Outbrain',
    logo: 'https://www.outbrain.com/images/logo.svg',
  },
  {
    id: 'taboola',
    name: 'Taboola',
    logo: 'https://www.taboola.com/wp-content/uploads/2017/11/taboola-logo.png',
  },
];

export default function Integrations() {
  const [connectedPlatforms, setConnectedPlatforms] = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    checkConnections();
  }, []);

  useEffect(() => {
    // Handle OAuth callbacks
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    
    if (code) {
      if (location.pathname.includes('/callback/google-ads')) {
        handleGoogleAdsCallback(code);
      } else if (location.pathname.includes('/callback/meta-ads')) {
        handleMetaAdsCallback(code);
      } else if (location.pathname.includes('/callback/tiktok-ads')) {
        handleTikTokAdsCallback(code);
      } else if (location.pathname.includes('/callback/outbrain')) {
        handleOutbrainCallback(code);
      } else if (location.pathname.includes('/callback/taboola')) {
        handleTaboolaCallback(code);
      }
    }
  }, [location]);

  const checkConnections = async () => {
    try {
      const [
        googleAdsConnected,
        metaAdsConnected,
        tiktokAdsConnected,
        outbrainConnected,
        taboolaConnected,
      ] = await Promise.all([
        GoogleAdsIntegration.checkConnection(),
        MetaAdsIntegration.checkConnection(),
        TikTokAdsIntegration.checkConnection(),
        OutbrainIntegration.checkConnection(),
        TaboolaIntegration.checkConnection(),
      ]);

      setConnectedPlatforms({
        google: googleAdsConnected,
        meta: metaAdsConnected,
        tiktok: tiktokAdsConnected,
        outbrain: outbrainConnected,
        taboola: taboolaConnected,
      });
    } catch (error) {
      console.error('Error checking connections:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnect = async (platform: AdPlatformType) => {
    try {
      switch (platform) {
        case 'google':
          await GoogleAdsIntegration.initiateOAuth();
          break;
        case 'meta':
          await MetaAdsIntegration.initiateOAuth();
          break;
        case 'tiktok':
          await TikTokAdsIntegration.initiateOAuth();
          break;
        case 'outbrain':
          await OutbrainIntegration.initiateOAuth();
          break;
        case 'taboola':
          await TaboolaIntegration.initiateOAuth();
          break;
      }
    } catch (error) {
      console.error(`Error connecting to ${platform}:`, error);
    }
  };

  const handleGoogleAdsCallback = async (code: string) => {
    try {
      await GoogleAdsIntegration.handleCallback(code);
      setConnectedPlatforms(prev => ({ ...prev, google: true }));
      navigate('/dashboard/integrations');
    } catch (error) {
      console.error('Error handling Google Ads callback:', error);
    }
  };

  const handleMetaAdsCallback = async (code: string) => {
    try {
      await MetaAdsIntegration.handleCallback(code);
      setConnectedPlatforms(prev => ({ ...prev, meta: true }));
      navigate('/dashboard/integrations');
    } catch (error) {
      console.error('Error handling Meta Ads callback:', error);
    }
  };

  const handleTikTokAdsCallback = async (code: string) => {
    try {
      await TikTokAdsIntegration.handleCallback(code);
      setConnectedPlatforms(prev => ({ ...prev, tiktok: true }));
      navigate('/dashboard/integrations');
    } catch (error) {
      console.error('Error handling TikTok Ads callback:', error);
    }
  };

  const handleOutbrainCallback = async (code: string) => {
    try {
      await OutbrainIntegration.handleCallback(code);
      setConnectedPlatforms(prev => ({ ...prev, outbrain: true }));
      navigate('/dashboard/integrations');
    } catch (error) {
      console.error('Error handling Outbrain callback:', error);
    }
  };

  const handleTaboolaCallback = async (code: string) => {
    try {
      await TaboolaIntegration.handleCallback(code);
      setConnectedPlatforms(prev => ({ ...prev, taboola: true }));
      navigate('/dashboard/integrations');
    } catch (error) {
      console.error('Error handling Taboola callback:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
      </div>
    );
  }

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Platform Integrations</h1>
        <p className="mt-2 text-sm text-gray-500">
          Connect your advertising accounts to start analyzing your campaigns
        </p>

        <div className="mt-8 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {platforms.map((platform) => (
            <div
              key={platform.id}
              className="relative rounded-lg border border-gray-200 bg-white p-6 shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="flex items-center space-x-4">
                <div className="h-12 w-12 flex-shrink-0">
                  <img
                    src={platform.logo}
                    alt={`${platform.name} logo`}
                    className="h-12 w-12 object-contain"
                  />
                </div>
                <div className="min-w-0 flex-1">
                  <h3 className="text-lg font-medium text-gray-900">
                    {platform.name}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {connectedPlatforms[platform.id] 
                      ? 'Connected and syncing data'
                      : `Connect your ${platform.name} account`}
                  </p>
                </div>
              </div>

              <div className="mt-6">
                <button
                  onClick={() => handleConnect(platform.id)}
                  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm ${
                    connectedPlatforms[platform.id]
                      ? 'text-green-700 bg-green-100 hover:bg-green-200'
                      : 'text-white bg-indigo-600 hover:bg-indigo-700'
                  } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  {connectedPlatforms[platform.id] ? (
                    <>
                      <CheckCircle className="h-4 w-4 mr-2" />
                      Connected
                    </>
                  ) : (
                    <>
                      <PlusCircle className="h-4 w-4 mr-2" />
                      Connect Account
                    </>
                  )}
                </button>
              </div>

              <div className="mt-4 flex items-center text-sm text-gray-500">
                <AlertCircle className="h-4 w-4 mr-2" />
                Requires {platform.name} account access
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}