import React, { useState, useEffect } from 'react';
import { Building2, Save } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { Company } from '../../types/company';
import { read, createOrUpdate } from '../../lib/db';
import { formatRelativeTime } from '../../lib/utils';

export default function CompanySettings() {
  const { user } = useAuth();
  const [company, setCompany] = useState<Company | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadCompany();
  }, [user]);

  const loadCompany = async () => {
    if (!user?.organizationId) return;

    try {
      let companyData = await read<Company>('companies', user.organizationId);
      
      if (!companyData) {
        companyData = {
          id: user.organizationId,
          name: 'My Company',
          industry: '',
          address: '',
          contactEmail: user.email,
          contactPhone: '',
          createdAt: new Date(),
          updatedAt: new Date(),
        };
        await createOrUpdate('companies', companyData);
      }
      
      setCompany(companyData);
    } catch (error) {
      console.error('Error loading company:', error);
      setError('Failed to load company data');
    }
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!company || !user?.organizationId) return;

    setIsSaving(true);
    setError(null);

    try {
      const updatedCompany = {
        ...company,
        updatedAt: new Date(),
      };
      
      await createOrUpdate('companies', updatedCompany);
      setCompany(updatedCompany);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving company:', error);
      setError('Failed to save company data');
    } finally {
      setIsSaving(false);
    }
  };

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <div className="mt-2 text-sm text-red-700">{error}</div>
          </div>
        </div>
      </div>
    );
  }

  if (!company) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
      </div>
    );
  }

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Company Information
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Update your company's basic information and contact details.
            </p>
            {company.updatedAt && (
              <div className="mt-4 flex items-center text-sm text-gray-500">
                Last updated {formatRelativeTime(company.updatedAt)}
              </div>
            )}
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={handleSave}>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-4">
                  <label htmlFor="company-name" className="block text-sm font-medium text-gray-700">
                    Company Name
                  </label>
                  <input
                    type="text"
                    name="company-name"
                    id="company-name"
                    value={company.name}
                    onChange={e => setCompany({ ...company, name: e.target.value })}
                    disabled={!isEditing}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <label htmlFor="industry" className="block text-sm font-medium text-gray-700">
                    Industry
                  </label>
                  <input
                    type="text"
                    name="industry"
                    id="industry"
                    value={company.industry}
                    onChange={e => setCompany({ ...company, industry: e.target.value })}
                    disabled={!isEditing}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6">
                  <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                    Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    value={company.address}
                    onChange={e => setCompany({ ...company, address: e.target.value })}
                    disabled={!isEditing}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                    Contact Email
                  </label>
                  <input
                    type="email"
                    name="contact-email"
                    id="contact-email"
                    value={company.contactEmail}
                    onChange={e => setCompany({ ...company, contactEmail: e.target.value })}
                    disabled={!isEditing}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="contact-phone" className="block text-sm font-medium text-gray-700">
                    Contact Phone
                  </label>
                  <input
                    type="tel"
                    name="contact-phone"
                    id="contact-phone"
                    value={company.contactPhone}
                    onChange={e => setCompany({ ...company, contactPhone: e.target.value })}
                    disabled={!isEditing}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                {!isEditing ? (
                  <button
                    type="button"
                    onClick={() => setIsEditing(true)}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Edit Information
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        setIsEditing(false);
                        loadCompany();
                      }}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSaving}
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {isSaving ? (
                        <>
                          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                          Saving...
                        </>
                      ) : (
                        <>
                          <Save className="h-4 w-4 mr-2" />
                          Save Changes
                        </>
                      )}
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}