import { env } from '../../config/env';

export interface OutbrainCredentials {
  accessToken: string;
  refreshToken: string;
  expiresAt: number;
}

export class OutbrainIntegration {
  static async initiateOAuth() {
    const redirectUri = `${window.location.origin}/dashboard/integrations/callback/outbrain`;
    
    const params = new URLSearchParams({
      client_id: env.outbrainClientId,
      redirect_uri: redirectUri,
      response_type: 'code',
      scope: 'native',
    });

    window.location.href = `https://api.outbrain.com/oauth/v2/authorize?${params.toString()}`;
  }

  static async handleCallback(code: string): Promise<OutbrainCredentials> {
    const response = await fetch(`${env.apiUrl}/integrations/outbrain/callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });

    if (!response.ok) {
      throw new Error('Failed to complete Outbrain integration');
    }

    return response.json();
  }

  static async checkConnection(): Promise<boolean> {
    try {
      const response = await fetch(`${env.apiUrl}/integrations/outbrain/status`);
      const data = await response.json();
      return data.connected;
    } catch {
      return false;
    }
  }
}