import React from 'react';
import { Calendar } from 'lucide-react';
import { DateRange } from '../../types/common';

interface CampaignFiltersProps {
  selectedPlatforms: string[];
  selectedStatuses: string[];
  dateRange: DateRange;
  onPlatformsChange: (platforms: string[]) => void;
  onStatusesChange: (statuses: string[]) => void;
  onDateRangeChange: (dateRange: DateRange) => void;
}

const platforms = [
  { id: 'google', name: 'Google Ads' },
  { id: 'meta', name: 'Meta Ads' },
  { id: 'tiktok', name: 'TikTok Ads' },
  { id: 'outbrain', name: 'Outbrain' },
  { id: 'taboola', name: 'Taboola' },
];

const statuses = [
  { id: 'active', name: 'Active' },
  { id: 'paused', name: 'Paused' },
  { id: 'ended', name: 'Ended' },
];

export default function CampaignFilters({
  selectedPlatforms,
  selectedStatuses,
  dateRange,
  onPlatformsChange,
  onStatusesChange,
  onDateRangeChange,
}: CampaignFiltersProps) {
  const handlePlatformToggle = (platformId: string) => {
    if (selectedPlatforms.includes(platformId)) {
      onPlatformsChange(selectedPlatforms.filter(id => id !== platformId));
    } else {
      onPlatformsChange([...selectedPlatforms, platformId]);
    }
  };

  const handleStatusToggle = (statusId: string) => {
    if (selectedStatuses.includes(statusId)) {
      onStatusesChange(selectedStatuses.filter(id => id !== statusId));
    } else {
      onStatusesChange([...selectedStatuses, statusId]);
    }
  };

  const handleDateRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const days = parseInt(e.target.value);
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - days);
    
    onDateRangeChange({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm space-y-4">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        <div>
          <label className="text-sm font-medium text-gray-700">Platforms</label>
          <div className="mt-2 flex flex-wrap gap-2">
            {platforms.map((platform) => (
              <button
                key={platform.id}
                onClick={() => handlePlatformToggle(platform.id)}
                className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                  selectedPlatforms.includes(platform.id)
                    ? 'bg-indigo-100 text-indigo-800'
                    : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                }`}
              >
                {platform.name}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="text-sm font-medium text-gray-700">Status</label>
          <div className="mt-2 flex flex-wrap gap-2">
            {statuses.map((status) => (
              <button
                key={status.id}
                onClick={() => handleStatusToggle(status.id)}
                className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                  selectedStatuses.includes(status.id)
                    ? 'bg-indigo-100 text-indigo-800'
                    : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                }`}
              >
                {status.name}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="text-sm font-medium text-gray-700">Date Range</label>
          <div className="mt-2">
            <div className="relative">
              <select
                className="block w-full pl-10 pr-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                onChange={handleDateRangeChange}
                defaultValue="7"
              >
                <option value="7">Last 7 days</option>
                <option value="30">Last 30 days</option>
                <option value="90">Last 90 days</option>
                <option value="custom">Custom Range</option>
              </select>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}