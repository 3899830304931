import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Save, AlertCircle } from 'lucide-react';
import { create, queryByIndex } from '../../lib/db';

interface PlatformCredentials {
  [key: string]: {
    apiKey?: string;
    clientId?: string;
    clientSecret?: string;
    refreshToken?: string;
    [key: string]: string | undefined;
  };
}

interface FieldError {
  [key: string]: string;
}

const platforms = [
  {
    id: 'google',
    name: 'Google Ads',
    fields: [
      { name: 'clientId', label: 'Client ID', type: 'text' },
      { name: 'clientSecret', label: 'Client Secret', type: 'password' },
      { name: 'refreshToken', label: 'Refresh Token', type: 'password' },
    ],
  },
  {
    id: 'meta',
    name: 'Meta Ads',
    fields: [
      { name: 'appId', label: 'App ID', type: 'text' },
      { name: 'appSecret', label: 'App Secret', type: 'password' },
      { name: 'accessToken', label: 'Access Token', type: 'password' },
    ],
  },
  {
    id: 'tiktok',
    name: 'TikTok Ads',
    fields: [
      { name: 'appId', label: 'App ID', type: 'text' },
      { name: 'appSecret', label: 'App Secret', type: 'password' },
      { name: 'accessToken', label: 'Access Token', type: 'password' },
    ],
  },
  {
    id: 'outbrain',
    name: 'Outbrain',
    fields: [
      { name: 'apiKey', label: 'API Key', type: 'password' },
      { name: 'clientId', label: 'Client ID', type: 'text' },
      { name: 'clientSecret', label: 'Client Secret', type: 'password' },
    ],
  },
  {
    id: 'taboola',
    name: 'Taboola',
    fields: [
      { name: 'clientId', label: 'Client ID', type: 'text' },
      { name: 'clientSecret', label: 'Client Secret', type: 'password' },
      { name: 'accountId', label: 'Account ID', type: 'text' },
    ],
  },
];

export default function IntegrationsSettings() {
  const { user } = useAuth();
  const [credentials, setCredentials] = useState<PlatformCredentials>({});
  const [saving, setSaving] = useState<{ [key: string]: boolean }>({});
  const [status, setStatus] = useState<{ [key: string]: { success?: boolean; message?: string } }>({});
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: FieldError }>({});

  useEffect(() => {
    loadCredentials();
  }, [user]);

  const loadCredentials = async () => {
    if (!user) return;

    try {
      const integrations = await queryByIndex<any>('platformIntegrations', 'by-user', user.id);
      
      const loadedCredentials: PlatformCredentials = {};
      integrations.forEach((integration) => {
        loadedCredentials[integration.platform] = integration.credentials;
      });
      setCredentials(loadedCredentials);
    } catch (error) {
      console.error('Error loading credentials:', error);
    }
  };

  const validateFields = (platformId: string, platformCreds: Record<string, string | undefined>) => {
    const errors: FieldError = {};
    const platform = platforms.find(p => p.id === platformId);
    
    if (platform) {
      platform.fields.forEach(field => {
        if (!platformCreds[field.name]?.trim()) {
          errors[field.name] = `${field.label} is required`;
        }
      });
    }
    
    return errors;
  };

  const handleInputChange = (platformId: string, field: string, value: string) => {
    setCredentials(prev => ({
      ...prev,
      [platformId]: {
        ...prev[platformId],
        [field]: value,
      },
    }));

    // Clear error for this field when user starts typing
    setFieldErrors(prev => ({
      ...prev,
      [platformId]: {
        ...prev[platformId],
        [field]: '',
      },
    }));
  };

  const handleSave = async (platformId: string) => {
    if (!user) return;

    const platformCreds = credentials[platformId] || {};
    const errors = validateFields(platformId, platformCreds);

    if (Object.keys(errors).length > 0) {
      setFieldErrors(prev => ({
        ...prev,
        [platformId]: errors,
      }));
      setStatus(prev => ({
        ...prev,
        [platformId]: { success: false, message: 'Please fill in all required fields' },
      }));
      return;
    }

    setSaving(prev => ({ ...prev, [platformId]: true }));
    setStatus(prev => ({ ...prev, [platformId]: {} }));
    setFieldErrors(prev => ({ ...prev, [platformId]: {} }));

    try {
      await create('platformIntegrations', {
        userId: user.id,
        platform: platformId,
        credentials: platformCreds,
        createdAt: new Date(),
        updatedAt: new Date()
      });

      setStatus(prev => ({
        ...prev,
        [platformId]: { success: true, message: 'Credentials saved successfully' },
      }));
    } catch (error) {
      setStatus(prev => ({
        ...prev,
        [platformId]: { success: false, message: 'Error saving credentials' },
      }));
    } finally {
      setSaving(prev => ({ ...prev, [platformId]: false }));
    }
  };

  return (
    <div className="space-y-6">
      {platforms.map((platform) => (
        <div key={platform.id} className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {platform.name} Integration
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>Configure your {platform.name} API credentials.</p>
            </div>
            <div className="mt-5 space-y-4">
              {platform.fields.map((field) => (
                <div key={field.name}>
                  <label htmlFor={`${platform.id}-${field.name}`} className="block text-sm font-medium text-gray-700">
                    {field.label}
                  </label>
                  <div className="mt-1">
                    <input
                      type={field.type}
                      name={`${platform.id}-${field.name}`}
                      id={`${platform.id}-${field.name}`}
                      placeholder={`Enter your ${field.label}`}
                      value={credentials[platform.id]?.[field.name] || ''}
                      onChange={(e) => handleInputChange(platform.id, field.name, e.target.value)}
                      className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                        fieldErrors[platform.id]?.[field.name]
                          ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                          : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                      }`}
                    />
                    {fieldErrors[platform.id]?.[field.name] && (
                      <p className="mt-1 text-sm text-red-600">
                        {fieldErrors[platform.id][field.name]}
                      </p>
                    )}
                  </div>
                </div>
              ))}
              <div className="mt-4">
                <button
                  type="button"
                  onClick={() => handleSave(platform.id)}
                  disabled={saving[platform.id]}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {saving[platform.id] ? (
                    <>
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                      Saving...
                    </>
                  ) : (
                    <>
                      <Save className="h-4 w-4 mr-2" />
                      Save Credentials
                    </>
                  )}
                </button>
              </div>
              {status[platform.id]?.message && (
                <div className={`mt-2 text-sm ${status[platform.id]?.success ? 'text-green-600' : 'text-red-600'}`}>
                  <div className="flex items-center">
                    <AlertCircle className="h-4 w-4 mr-1" />
                    {status[platform.id]?.message}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}