import { env } from '../../config/env';

export interface MetaAdsCredentials {
  accessToken: string;
  refreshToken: string;
  expiresAt: number;
}

export class MetaAdsIntegration {
  static async initiateOAuth() {
    const redirectUri = `${window.location.origin}/dashboard/integrations/callback/meta-ads`;
    
    const params = new URLSearchParams({
      client_id: env.metaAppId,
      redirect_uri: redirectUri,
      scope: 'ads_management,ads_read',
      response_type: 'code',
      state: 'meta-ads',
    });

    window.location.href = `https://www.facebook.com/v18.0/dialog/oauth?${params.toString()}`;
  }

  static async handleCallback(code: string): Promise<MetaAdsCredentials> {
    const response = await fetch(`${env.apiUrl}/integrations/meta-ads/callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });

    if (!response.ok) {
      throw new Error('Failed to complete Meta Ads integration');
    }

    return response.json();
  }

  static async checkConnection(): Promise<boolean> {
    try {
      const response = await fetch(`${env.apiUrl}/integrations/meta-ads/status`);
      const data = await response.json();
      return data.connected;
    } catch {
      return false;
    }
  }
}