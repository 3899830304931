import { env } from '../../config/env';

export interface TaboolaCredentials {
  accessToken: string;
  refreshToken: string;
  expiresAt: number;
}

export class TaboolaIntegration {
  static async initiateOAuth() {
    const redirectUri = `${window.location.origin}/dashboard/integrations/callback/taboola`;
    
    const params = new URLSearchParams({
      client_id: env.taboolaClientId,
      redirect_uri: redirectUri,
      response_type: 'code',
      scope: 'read write',
    });

    window.location.href = `https://backstage.taboola.com/backstage/oauth/authorize?${params.toString()}`;
  }

  static async handleCallback(code: string): Promise<TaboolaCredentials> {
    const response = await fetch(`${env.apiUrl}/integrations/taboola/callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });

    if (!response.ok) {
      throw new Error('Failed to complete Taboola integration');
    }

    return response.json();
  }

  static async checkConnection(): Promise<boolean> {
    try {
      const response = await fetch(`${env.apiUrl}/integrations/taboola/status`);
      const data = await response.json();
      return data.connected;
    } catch {
      return false;
    }
  }
}