import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/ui/tabs';
import ReportTemplates from '../../components/reports/ReportTemplates';
import CustomReports from '../../components/reports/CustomReports';
import ReportDetails from '../../components/reports/ReportDetails';

export default function ReportsPage() {
  const [activeTab, setActiveTab] = useState('templates');
  const [selectedReport, setSelectedReport] = useState<string | null>(null);

  // If a report is selected, show its details
  if (selectedReport) {
    return (
      <ReportDetails
        reportId={selectedReport}
        onBack={() => setSelectedReport(null)}
      />
    );
  }

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Reports</h1>
        <p className="mt-2 text-sm text-gray-500">
          Access pre-built templates or create custom reports to analyze your marketing performance
        </p>

        <div className="mt-6">
          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList>
              <TabsTrigger value="templates">Templates</TabsTrigger>
              <TabsTrigger value="custom">Custom Reports</TabsTrigger>
            </TabsList>
            <TabsContent value="templates">
              <ReportTemplates onSelectReport={setSelectedReport} />
            </TabsContent>
            <TabsContent value="custom">
              <CustomReports onSelectReport={setSelectedReport} />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
}