import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Filter, Search, Download } from 'lucide-react';
import CampaignTable from '../../components/campaigns/CampaignTable';
import CampaignFilters from '../../components/campaigns/CampaignFilters';
import CampaignPerformanceMetrics from '../../components/campaigns/CampaignPerformanceMetrics';
import { useCampaigns } from '../../hooks/useCampaigns';
import { Campaign } from '../../types/campaigns';
import { DateRange } from '../../types/common';

export default function CampaignListPage() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
    endDate: new Date().toISOString(),
  });

  const { data: campaigns = [], isLoading, error } = useCampaigns({
    platforms: selectedPlatforms,
    statuses: selectedStatuses,
    dateRange,
    search: searchQuery,
  });

  const handleCreateCampaign = () => {
    navigate('/dashboard/campaigns/create');
  };

  const handleExportData = () => {
    // TODO: Implement export functionality
    console.log('Exporting campaign data...');
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Campaigns</h1>
          <p className="mt-2 text-sm text-gray-700">
            Manage and monitor your advertising campaigns across all platforms
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none space-x-4">
          <button
            onClick={handleExportData}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Download className="h-4 w-4 mr-2" />
            Export
          </button>
          <button
            onClick={handleCreateCampaign}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Plus className="h-4 w-4 mr-2" />
            Create Campaign
          </button>
        </div>
      </div>

      <div className="mt-8 space-y-6">
        {/* Performance Metrics */}
        <CampaignPerformanceMetrics campaigns={campaigns} />

        {/* Filters and Search */}
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Search campaigns..."
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="flex-none">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <Filter className="h-4 w-4 mr-2" />
              Filters
            </button>
          </div>
        </div>

        <CampaignFilters
          selectedPlatforms={selectedPlatforms}
          selectedStatuses={selectedStatuses}
          dateRange={dateRange}
          onPlatformsChange={setSelectedPlatforms}
          onStatusesChange={setSelectedStatuses}
          onDateRangeChange={setDateRange}
        />

        {error ? (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  Error loading campaigns
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  {error instanceof Error ? error.message : 'An error occurred'}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <CampaignTable
            campaigns={campaigns}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}