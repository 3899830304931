import bcrypt from 'bcryptjs';
import { create, queryByIndex } from '../../lib/db';
import type { User, RegisterCredentials, AuthResponse } from '../../types/auth';

export class AuthService {
  static async register(credentials: RegisterCredentials): Promise<AuthResponse> {
    try {
      // Check if user already exists
      const existingUsers = await queryByIndex<User>('users', 'by-email', credentials.email);
      if (existingUsers.length > 0) {
        throw new Error('Email already registered');
      }

      // Hash password
      const hashedPassword = await bcrypt.hash(credentials.password, 10);

      // Create new user
      const newUser: User = {
        id: crypto.randomUUID(),
        name: credentials.name,
        email: credentials.email,
        password: hashedPassword,
        role: 'user',
        organizationId: crypto.randomUUID(), // Create new organization for user
        emailVerified: false,
        provider: 'email',
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      // Save user to database
      await create('users', newUser);

      // Generate JWT token (mock for now)
      const token = 'mock-jwt-token';

      // Return user without password
      const { password, ...userWithoutPassword } = newUser;
      return { user: userWithoutPassword, token };
    } catch (error) {
      throw new Error(error.message || 'Registration failed');
    }
  }
}