import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { TrendingUp, Users, MousePointerClick, DollarSign } from 'lucide-react';
import { formatCurrency, formatNumber, formatPercentage } from '../../lib/utils';
import { Campaign } from '../../types/campaigns';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface CampaignPerformanceMetricsProps {
  campaigns: Campaign[];
}

export default function CampaignPerformanceMetrics({ campaigns }: CampaignPerformanceMetricsProps) {
  // Calculate total metrics
  const totalMetrics = campaigns.reduce(
    (acc, campaign) => ({
      spend: acc.spend + campaign.budget,
      impressions: acc.impressions + campaign.impressions,
      clicks: acc.clicks + campaign.clicks,
      conversions: acc.conversions + campaign.conversions,
    }),
    { spend: 0, impressions: 0, clicks: 0, conversions: 0 }
  );

  // Calculate averages
  const avgCTR = (totalMetrics.clicks / totalMetrics.impressions) * 100 || 0;
  const avgCPC = totalMetrics.spend / totalMetrics.clicks || 0;
  const avgROAS = campaigns.reduce((acc, c) => acc + c.roas, 0) / campaigns.length || 0;

  // Prepare chart data
  const chartData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Clicks',
        data: [650, 590, 800, 810, 760, 550, 400],
        borderColor: 'rgb(99, 102, 241)',
        backgroundColor: 'rgba(99, 102, 241, 0.1)',
        fill: true,
        tension: 0.4,
      },
      {
        label: 'Conversions',
        data: [230, 190, 300, 310, 260, 150, 100],
        borderColor: 'rgb(34, 197, 94)',
        backgroundColor: 'rgba(34, 197, 94, 0.1)',
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const stats = [
    {
      name: 'Total Spend',
      value: formatCurrency(totalMetrics.spend),
      icon: DollarSign,
      change: '+12.5%',
      changeType: 'increase',
    },
    {
      name: 'Total Impressions',
      value: formatNumber(totalMetrics.impressions),
      icon: Users,
      change: '+25.3%',
      changeType: 'increase',
    },
    {
      name: 'Average CTR',
      value: formatPercentage(avgCTR),
      icon: MousePointerClick,
      change: '+4.1%',
      changeType: 'increase',
    },
    {
      name: 'Average ROAS',
      value: `${avgROAS.toFixed(2)}x`,
      icon: TrendingUp,
      change: '+18.2%',
      changeType: 'increase',
    },
  ];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((stat) => (
          <div key={stat.name} className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <stat.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      {stat.name}
                    </dt>
                    <dd className="flex items-baseline">
                      <div className="text-2xl font-semibold text-gray-900">
                        {stat.value}
                      </div>
                      <div className={`ml-2 flex items-baseline text-sm font-semibold ${
                        stat.changeType === 'increase' ? 'text-green-600' : 'text-red-600'
                      }`}>
                        {stat.change}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
            Performance Trends
          </h3>
          <div className="h-[300px]">
            <Line options={chartOptions} data={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
}