import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'sonner';

interface UseInactivityDetectionProps {
  timeoutMinutes?: number;
  showWarning?: boolean;
  warningMinutes?: number;
  onTimeout?: () => void;
}

export function useInactivityDetection({
  timeoutMinutes = 15,
  showWarning = true,
  warningMinutes = 2,
  onTimeout,
}: UseInactivityDetectionProps = {}) {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const warningTimeoutRef = useRef<NodeJS.Timeout>();
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  const handleLogout = () => {
    // Execute custom timeout handler if provided
    if (onTimeout) {
      onTimeout();
    }

    // Clear any existing timeouts
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (warningTimeoutRef.current) {
      clearTimeout(warningTimeoutRef.current);
    }

    // Perform logout
    logout();
    
    // Redirect to login with message
    navigate('/auth?message=session_expired');
  };

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (warningTimeoutRef.current) {
      clearTimeout(warningTimeoutRef.current);
    }

    setShowWarningDialog(false);

    // Set warning timeout if enabled
    if (showWarning) {
      const warningDelay = (timeoutMinutes - warningMinutes) * 60 * 1000;
      warningTimeoutRef.current = setTimeout(() => {
        setShowWarningDialog(true);
        toast.warning(`You will be logged out in ${warningMinutes} minutes due to inactivity`, {
          duration: warningMinutes * 60 * 1000,
        });
      }, warningDelay);
    }

    // Set main timeout
    timeoutRef.current = setTimeout(handleLogout, timeoutMinutes * 60 * 1000);
  };

  useEffect(() => {
    // List of events to monitor
    const events = [
      'mousedown',
      'mousemove',
      'keypress',
      'scroll',
      'touchstart',
      'click',
      'wheel',
    ];

    // Reset timer on any user activity
    const handleActivity = () => {
      resetTimer();
    };

    // Add event listeners
    events.forEach(event => {
      document.addEventListener(event, handleActivity);
    });

    // Initialize timer
    resetTimer();

    // Cleanup
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (warningTimeoutRef.current) {
        clearTimeout(warningTimeoutRef.current);
      }
      events.forEach(event => {
        document.removeEventListener(event, handleActivity);
      });
    };
  }, [timeoutMinutes, warningMinutes, showWarning]);

  return {
    showWarningDialog,
    resetTimer,
  };
}