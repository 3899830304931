import React, { useState } from 'react';
import { Bell, Filter, Search } from 'lucide-react';
import { useNotifications } from '../../hooks/useNotifications';
import { NotificationType, NotificationPriority } from '../../types/notifications';
import NotificationList from '../../components/notifications/NotificationList';
import NotificationFilters from '../../components/notifications/NotificationFilters';

export default function NotificationInboxPage() {
  const { notifications, markAsRead } = useNotifications();
  const [selectedTypes, setSelectedTypes] = useState<NotificationType[]>([]);
  const [selectedPriorities, setSelectedPriorities] = useState<NotificationPriority[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredNotifications = notifications.filter(notification => {
    const matchesType = selectedTypes.length === 0 || selectedTypes.includes(notification.type);
    const matchesPriority = selectedPriorities.length === 0 || selectedPriorities.includes(notification.priority);
    const matchesSearch = notification.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         notification.message.toLowerCase().includes(searchQuery.toLowerCase());
    
    return matchesType && matchesPriority && matchesSearch;
  });

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Notifications
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            View and manage your notifications
          </p>
        </div>
      </div>

      <div className="mt-4">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Search notifications..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="flex-none">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <Filter className="h-4 w-4 mr-2" />
              Filters
            </button>
          </div>
        </div>

        <div className="mt-4">
          <NotificationFilters
            selectedTypes={selectedTypes}
            selectedPriorities={selectedPriorities}
            onTypeChange={setSelectedTypes}
            onPriorityChange={setSelectedPriorities}
          />
        </div>

        <div className="mt-6">
          <NotificationList
            notifications={filteredNotifications}
            onMarkAsRead={markAsRead}
          />
        </div>
      </div>
    </div>
  );
}