interface EnvConfig {
  googleClientId: string;
  googleAdsApiScope: string;
  metaAppId: string;
  metaClientToken: string;
  tiktokAppId: string;
  outbrainClientId: string;
  taboolaClientId: string;
  apiUrl: string;
  salesforceClientId: string;
  hubspotClientId: string;
  zohoClientId: string;
  pipedriveClientId: string;
  freshsalesClientId: string;
  sugarcrmClientId: string;
  activecampaignClientId: string;
  insightlyClientId: string;
  keapClientId: string;
}

export const env: EnvConfig = {
  googleClientId: import.meta.env.VITE_GOOGLE_CLIENT_ID || '',
  googleAdsApiScope: import.meta.env.VITE_GOOGLE_ADS_API_SCOPE || '',
  metaAppId: import.meta.env.VITE_META_APP_ID || '',
  metaClientToken: import.meta.env.VITE_META_CLIENT_TOKEN || '',
  tiktokAppId: import.meta.env.VITE_TIKTOK_APP_ID || '',
  outbrainClientId: import.meta.env.VITE_OUTBRAIN_CLIENT_ID || '',
  taboolaClientId: import.meta.env.VITE_TABOOLA_CLIENT_ID || '',
  apiUrl: import.meta.env.VITE_API_URL || '/api',
  salesforceClientId: import.meta.env.VITE_SALESFORCE_CLIENT_ID || '',
  hubspotClientId: import.meta.env.VITE_HUBSPOT_CLIENT_ID || '',
  zohoClientId: import.meta.env.VITE_ZOHO_CLIENT_ID || '',
  pipedriveClientId: import.meta.env.VITE_PIPEDRIVE_CLIENT_ID || '',
  freshsalesClientId: import.meta.env.VITE_FRESHSALES_CLIENT_ID || '',
  sugarcrmClientId: import.meta.env.VITE_SUGARCRM_CLIENT_ID || '',
  activecampaignClientId: import.meta.env.VITE_ACTIVECAMPAIGN_CLIENT_ID || '',
  insightlyClientId: import.meta.env.VITE_INSIGHTLY_CLIENT_ID || '',
  keapClientId: import.meta.env.VITE_KEAP_CLIENT_ID || '',
};