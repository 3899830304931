import React from 'react';
import { ArrowUp, ArrowDown } from 'lucide-react';
import { DateRange } from '../../../types/common';
import { formatCurrency, formatNumber, formatPercentage } from '../../../lib/utils';

interface MetricsTableProps {
  dateRange: DateRange;
}

export default function MetricsTable({ dateRange }: MetricsTableProps) {
  // Sample data - replace with actual data
  const metrics = [
    {
      channel: 'Google Ads',
      spend: 5000,
      impressions: 150000,
      clicks: 7500,
      conversions: 250,
      ctr: 5,
      cpc: 0.67,
      roas: 3.2,
      trend: 'up',
    },
    {
      channel: 'Meta Ads',
      spend: 3500,
      impressions: 200000,
      clicks: 6000,
      conversions: 180,
      ctr: 3,
      cpc: 0.58,
      roas: 2.8,
      trend: 'down',
    },
    {
      channel: 'TikTok Ads',
      spend: 2500,
      impressions: 175000,
      clicks: 5250,
      conversions: 140,
      ctr: 3,
      cpc: 0.48,
      roas: 2.5,
      trend: 'up',
    },
  ];

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Channel
            </th>
            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Spend
            </th>
            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Impressions
            </th>
            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Clicks
            </th>
            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Conversions
            </th>
            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              CTR
            </th>
            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              CPC
            </th>
            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              ROAS
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {metrics.map((metric) => (
            <tr key={metric.channel}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="flex items-center">
                  {metric.channel}
                  {metric.trend === 'up' ? (
                    <ArrowUp className="h-4 w-4 ml-1 text-green-500" />
                  ) : (
                    <ArrowDown className="h-4 w-4 ml-1 text-red-500" />
                  )}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {formatCurrency(metric.spend)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {formatNumber(metric.impressions)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {formatNumber(metric.clicks)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {formatNumber(metric.conversions)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {formatPercentage(metric.ctr)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {formatCurrency(metric.cpc)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {metric.roas.toFixed(1)}x
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}