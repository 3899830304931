import { openDB, DBSchema, IDBPDatabase } from 'idb';

interface User {
  id: string;
  name: string;
  email: string;
  password: string; // Hashed password
  role: 'admin' | 'user';
  organizationId: string;
  emailVerified: boolean;
  provider: 'email' | 'google';
  createdAt: Date;
  updatedAt: Date;
}

interface MarketingPlatformDB extends DBSchema {
  users: {
    key: string;
    value: User;
    indexes: { 'by-email': string };
  };
}

let dbInstance: IDBPDatabase<MarketingPlatformDB> | null = null;

async function getDb() {
  if (!dbInstance) {
    dbInstance = await openDB<MarketingPlatformDB>('marketing-platform-db', 1, {
      upgrade(db, oldVersion, newVersion) {
        // Create users store
        if (!db.objectStoreNames.contains('users')) {
          const userStore = db.createObjectStore('users', {
            keyPath: 'id',
          });
          userStore.createIndex('by-email', 'email', { unique: true });
        }
      },
    });
  }
  return dbInstance;
}

export async function create<T>(store: string, data: T): Promise<T> {
  const db = await getDb();
  const tx = db.transaction(store, 'readwrite');
  await tx.store.add(data);
  await tx.done;
  return data;
}

export async function update<T extends { id: string }>(store: string, id: string, data: T): Promise<T> {
  const db = await getDb();
  const tx = db.transaction(store, 'readwrite');
  await tx.store.put(data);
  await tx.done;
  return data;
}

export async function createOrUpdate<T extends { id: string }>(store: string, data: T): Promise<T> {
  const db = await getDb();
  const tx = db.transaction(store, 'readwrite');
  await tx.store.put(data);
  await tx.done;
  return data;
}

export async function read<T>(store: string, id: string): Promise<T | undefined> {
  const db = await getDb();
  return db.get(store, id);
}

export async function remove(store: string, id: string): Promise<void> {
  const db = await getDb();
  await db.delete(store, id);
}

export async function queryByIndex<T>(
  store: string,
  indexName: string,
  query: IDBValidKey | IDBKeyRange
): Promise<T[]> {
  const db = await getDb();
  const tx = db.transaction(store, 'readonly');
  const index = tx.store.index(indexName);
  return index.getAll(query);
}

export async function getAllFromStore<T>(store: string): Promise<T[]> {
  const db = await getDb();
  return db.getAll(store);
}