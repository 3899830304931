import React from 'react';
import { Link2, CheckCircle, XCircle, AlertTriangle, RefreshCw, Settings } from 'lucide-react';
import { CrmIntegration } from '../../types/crm';
import { formatRelativeTime } from '../../lib/utils';

interface CrmIntegrationCardProps {
  integration: CrmIntegration;
  onConnect: () => void;
  onDisconnect: () => void;
  onTest: () => void;
  onConfigure: () => void;
}

export default function CrmIntegrationCard({
  integration,
  onConnect,
  onDisconnect,
  onTest,
  onConfigure,
}: CrmIntegrationCardProps) {
  const getStatusIcon = () => {
    switch (integration.status) {
      case 'connected':
        return <CheckCircle className="h-5 w-5 text-green-500" />;
      case 'disconnected':
        return <XCircle className="h-5 w-5 text-gray-400" />;
      case 'error':
        return <AlertTriangle className="h-5 w-5 text-red-500" />;
    }
  };

  const getStatusBadgeColor = () => {
    switch (integration.status) {
      case 'connected':
        return 'bg-green-100 text-green-800';
      case 'disconnected':
        return 'bg-gray-100 text-gray-800';
      case 'error':
        return 'bg-red-100 text-red-800';
    }
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
      <div className="px-4 py-5 sm:px-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link2 className="h-8 w-8 text-gray-400" />
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-medium text-gray-900">
                {integration.platform.charAt(0).toUpperCase() + integration.platform.slice(1)}
              </h3>
              <p className="text-sm text-gray-500">
                Last synced: {integration.lastSyncAt ? formatRelativeTime(integration.lastSyncAt) : 'Never'}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor()}`}>
              {getStatusIcon()}
              <span className="ml-1">{integration.status}</span>
            </span>
          </div>
        </div>
      </div>
      <div className="px-4 py-4 sm:px-6">
        <div className="flex justify-between items-center">
          <div className="flex space-x-4">
            {integration.status === 'connected' ? (
              <>
                <button
                  onClick={onTest}
                  className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <RefreshCw className="h-4 w-4 mr-2" />
                  Test Connection
                </button>
                <button
                  onClick={onConfigure}
                  className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <Settings className="h-4 w-4 mr-2" />
                  Configure
                </button>
              </>
            ) : null}
          </div>
          <button
            onClick={integration.status === 'connected' ? onDisconnect : onConnect}
            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
              integration.status === 'connected'
                ? 'bg-red-600 hover:bg-red-700'
                : 'bg-indigo-600 hover:bg-indigo-700'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            {integration.status === 'connected' ? 'Disconnect' : 'Connect'}
          </button>
        </div>
        {integration.error && (
          <div className="mt-2 text-sm text-red-600">
            {integration.error}
          </div>
        )}
      </div>
    </div>
  );
}