import { CrmPlatform } from '../../types/crm';
import { env } from '../../config/env';

export class CrmService {
  private platform: CrmPlatform;

  constructor(platform: CrmPlatform) {
    this.platform = platform;
  }

  async initiateOAuth() {
    const redirectUri = `${window.location.origin}/dashboard/settings/crm/callback/${this.platform}`;
    
    switch (this.platform) {
      case 'salesforce':
        window.location.href = `https://login.salesforce.com/services/oauth2/authorize?client_id=${env.salesforceClientId}&redirect_uri=${redirectUri}&response_type=code`;
        break;
      case 'hubspot':
        window.location.href = `https://app.hubspot.com/oauth/authorize?client_id=${env.hubspotClientId}&redirect_uri=${redirectUri}&scope=contacts%20timeline`;
        break;
      // Add other CRM platforms as needed
      default:
        throw new Error(`OAuth not implemented for ${this.platform}`);
    }
  }

  async handleCallback(code: string) {
    const response = await fetch(`${env.apiUrl}/crm/${this.platform}/callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });

    if (!response.ok) {
      throw new Error(`Failed to complete ${this.platform} integration`);
    }

    return response.json();
  }

  async testConnection(credentials: Record<string, string>) {
    try {
      const response = await fetch(`${env.apiUrl}/crm/${this.platform}/test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${credentials.accessToken}`,
        },
      });

      return response.ok;
    } catch {
      return false;
    }
  }

  async syncData(credentials: Record<string, string>, lastSyncDate?: Date) {
    const response = await fetch(`${env.apiUrl}/crm/${this.platform}/sync`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${credentials.accessToken}`,
      },
      body: JSON.stringify({ lastSyncDate }),
    });

    if (!response.ok) {
      throw new Error(`Failed to sync data with ${this.platform}`);
    }

    return response.json();
  }
}