import React from 'react';
import { NotificationType, NotificationPriority } from '../../types/notifications';
import { cn } from '../../lib/utils';

interface NotificationFiltersProps {
  selectedTypes: NotificationType[];
  selectedPriorities: NotificationPriority[];
  onTypeChange: (types: NotificationType[]) => void;
  onPriorityChange: (priorities: NotificationPriority[]) => void;
}

export default function NotificationFilters({
  selectedTypes,
  selectedPriorities,
  onTypeChange,
  onPriorityChange,
}: NotificationFiltersProps) {
  const types: { id: NotificationType; name: string }[] = [
    { id: 'system', name: 'System' },
    { id: 'campaign', name: 'Campaign' },
  ];

  const priorities: { id: NotificationPriority; name: string }[] = [
    { id: 'high', name: 'High' },
    { id: 'medium', name: 'Medium' },
    { id: 'low', name: 'Low' },
  ];

  const handleTypeToggle = (typeId: NotificationType) => {
    if (selectedTypes.includes(typeId)) {
      onTypeChange(selectedTypes.filter(id => id !== typeId));
    } else {
      onTypeChange([...selectedTypes, typeId]);
    }
  };

  const handlePriorityToggle = (priorityId: NotificationPriority) => {
    if (selectedPriorities.includes(priorityId)) {
      onPriorityChange(selectedPriorities.filter(id => id !== priorityId));
    } else {
      onPriorityChange([...selectedPriorities, priorityId]);
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm space-y-4">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div>
          <label className="text-sm font-medium text-gray-700">Type</label>
          <div className="mt-2 flex flex-wrap gap-2">
            {types.map((type) => (
              <button
                key={type.id}
                onClick={() => handleTypeToggle(type.id)}
                className={cn(
                  'inline-flex items-center px-3 py-1 rounded-full text-sm font-medium',
                  selectedTypes.includes(type.id)
                    ? 'bg-indigo-100 text-indigo-800'
                    : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                )}
              >
                {type.name}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="text-sm font-medium text-gray-700">Priority</label>
          <div className="mt-2 flex flex-wrap gap-2">
            {priorities.map((priority) => (
              <button
                key={priority.id}
                onClick={() => handlePriorityToggle(priority.id)}
                className={cn(
                  'inline-flex items-center px-3 py-1 rounded-full text-sm font-medium',
                  selectedPriorities.includes(priority.id)
                    ? 'bg-indigo-100 text-indigo-800'
                    : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                )}
              >
                {priority.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}