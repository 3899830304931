import React, { useState } from 'react';
import { ArrowLeft, Download, Share2, Calendar } from 'lucide-react';
import { DateRange } from '../../types/common';
import PerformanceChart from './charts/PerformanceChart';
import AttributionChart from './charts/AttributionChart';
import MetricsTable from './tables/MetricsTable';

interface ReportDetailsProps {
  reportId: string;
  onBack: () => void;
}

export default function ReportDetails({ reportId, onBack }: ReportDetailsProps) {
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(),
    endDate: new Date().toISOString(),
  });

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <button
              onClick={onBack}
              className="mr-4 text-gray-400 hover:text-gray-500"
            >
              <ArrowLeft className="h-5 w-5" />
            </button>
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">
                Campaign Performance Overview
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                Detailed analysis of campaign performance across all channels
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
              <Share2 className="h-4 w-4 mr-2" />
              Share
            </button>
            <button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
              <Download className="h-4 w-4 mr-2" />
              Export
            </button>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-1 gap-6 lg:grid-cols-2">
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Performance Trends
            </h3>
            <PerformanceChart dateRange={dateRange} />
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Attribution Analysis
            </h3>
            <AttributionChart dateRange={dateRange} />
          </div>
        </div>

        <div className="mt-6">
          <div className="bg-white rounded-lg shadow">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Detailed Metrics
              </h3>
              <MetricsTable dateRange={dateRange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}