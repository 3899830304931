import React, { useState, useEffect } from 'react';
import { Clock, Filter, User, Calendar } from 'lucide-react';
import { ActivityLog } from '../../types/company';
import { useAuth } from '../../contexts/AuthContext';
import { queryByIndex } from '../../lib/db';
import ActivityFilters from '../../components/activity/ActivityFilters';
import ActivityTable from '../../components/activity/ActivityTable';

export default function ActivityLogPage() {
  const { user } = useAuth();
  const [activities, setActivities] = useState<ActivityLog[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    users: [] as string[],
    actions: [] as string[],
    dateRange: {
      startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
      endDate: new Date().toISOString(),
    },
  });

  useEffect(() => {
    loadActivities();
  }, [user, filters]);

  const loadActivities = async () => {
    if (!user?.organizationId) return;

    setIsLoading(true);
    try {
      const logs = await queryByIndex<ActivityLog>(
        'activityLogs',
        'by-company',
        user.organizationId
      );

      // Apply filters
      const filteredLogs = logs.filter(log => {
        const dateInRange = new Date(log.createdAt) >= new Date(filters.dateRange.startDate) &&
                          new Date(log.createdAt) <= new Date(filters.dateRange.endDate);
        const userMatch = filters.users.length === 0 || filters.users.includes(log.userId);
        const actionMatch = filters.actions.length === 0 || filters.actions.includes(log.action);
        
        return dateInRange && userMatch && actionMatch;
      });

      setActivities(filteredLogs);
    } catch (error) {
      console.error('Error loading activities:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Activity Log
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            Track all actions and changes made by team members
          </p>
        </div>
      </div>

      <div className="mt-8">
        <ActivityFilters
          filters={filters}
          onFiltersChange={setFilters}
        />

        <div className="mt-8">
          <ActivityTable
            activities={activities}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}