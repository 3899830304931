import React from 'react';
import { Calendar, Filter, User } from 'lucide-react';
import { DateRange } from '../../types/common';

interface ActivityFiltersProps {
  filters: {
    users: string[];
    actions: string[];
    dateRange: DateRange;
  };
  onFiltersChange: (filters: {
    users: string[];
    actions: string[];
    dateRange: DateRange;
  }) => void;
}

const actionTypes = [
  { id: 'campaign_created', name: 'Campaign Created' },
  { id: 'campaign_updated', name: 'Campaign Updated' },
  { id: 'settings_updated', name: 'Settings Updated' },
  { id: 'member_added', name: 'Member Added' },
  { id: 'member_removed', name: 'Member Removed' },
  { id: 'member_updated', name: 'Member Updated' },
];

export default function ActivityFilters({ filters, onFiltersChange }: ActivityFiltersProps) {
  const handleActionToggle = (actionId: string) => {
    const newActions = filters.actions.includes(actionId)
      ? filters.actions.filter(id => id !== actionId)
      : [...filters.actions, actionId];
    
    onFiltersChange({ ...filters, actions: newActions });
  };

  const handleDateRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const days = parseInt(e.target.value);
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - days);
    
    onFiltersChange({
      ...filters,
      dateRange: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    });
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm space-y-4">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div>
          <label className="text-sm font-medium text-gray-700">Action Type</label>
          <div className="mt-2 flex flex-wrap gap-2">
            {actionTypes.map((action) => (
              <button
                key={action.id}
                onClick={() => handleActionToggle(action.id)}
                className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                  filters.actions.includes(action.id)
                    ? 'bg-indigo-100 text-indigo-800'
                    : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                }`}
              >
                {action.name}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="text-sm font-medium text-gray-700">Date Range</label>
          <div className="mt-2">
            <div className="relative">
              <select
                className="block w-full pl-10 pr-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                onChange={handleDateRangeChange}
                defaultValue="7"
              >
                <option value="1">Last 24 hours</option>
                <option value="7">Last 7 days</option>
                <option value="30">Last 30 days</option>
                <option value="90">Last 90 days</option>
              </select>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}