import { env } from '../../config/env';

export interface TikTokAdsCredentials {
  accessToken: string;
  refreshToken: string;
  expiresAt: number;
}

export class TikTokAdsIntegration {
  static async initiateOAuth() {
    const redirectUri = `${window.location.origin}/dashboard/integrations/callback/tiktok-ads`;
    
    const params = new URLSearchParams({
      app_id: env.tiktokAppId,
      redirect_uri: redirectUri,
      state: 'tiktok-ads',
      response_type: 'code',
      scope: 'ads.read,ads.write',
    });

    window.location.href = `https://ads.tiktok.com/marketing_api/auth?${params.toString()}`;
  }

  static async handleCallback(code: string): Promise<TikTokAdsCredentials> {
    const response = await fetch(`${env.apiUrl}/integrations/tiktok-ads/callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });

    if (!response.ok) {
      throw new Error('Failed to complete TikTok Ads integration');
    }

    return response.json();
  }

  static async checkConnection(): Promise<boolean> {
    try {
      const response = await fetch(`${env.apiUrl}/integrations/tiktok-ads/status`);
      const data = await response.json();
      return data.connected;
    } catch {
      return false;
    }
  }
}