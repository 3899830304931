import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Building2, Users, Clock, Link as LinkIcon, Bell, Shield, User, Users2 } from 'lucide-react';
import { cn } from '../lib/utils';
import IntegrationsSettings from '../components/settings/IntegrationsSettings';
import CompanySettings from '../components/settings/CompanySettings';
import NotificationSettings from '../components/settings/NotificationSettings';
import CrmSettings from '../components/settings/CrmSettings';
import TeamSettings from '../components/settings/TeamSettings';
import ActivitySettings from '../components/settings/ActivitySettings';
import ProfileSettings from '../components/settings/ProfileSettings';

const tabs = [
  { id: 'company', name: 'Company', icon: Building2 },
  { id: 'team', name: 'Team', icon: Users2 },
  { id: 'integrations', name: 'Integrations', icon: LinkIcon },
  { id: 'crm', name: 'CRM', icon: Users },
  { id: 'notifications', name: 'Notifications', icon: Bell },
  { id: 'activity', name: 'Activity Log', icon: Clock },
  { id: 'security', name: 'Security', icon: Shield },
  { id: 'profile', name: 'Profile', icon: User },
];

export default function Settings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'company');

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [searchParams]);

  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
    setSearchParams({ tab: tabId });
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'company':
        return <CompanySettings />;
      case 'team':
        return <TeamSettings />;
      case 'integrations':
        return <IntegrationsSettings />;
      case 'crm':
        return <CrmSettings />;
      case 'notifications':
        return <NotificationSettings />;
      case 'activity':
        return <ActivitySettings />;
      case 'security':
        return <div>Security Settings Coming Soon</div>;
      case 'profile':
        return <ProfileSettings />;
      default:
        return null;
    }
  };

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>
        <p className="mt-2 text-sm text-gray-500">
          Manage your account settings and platform preferences
        </p>

        <div className="mt-6">
          <div className="sm:hidden">
            <select
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              value={activeTab}
              onChange={(e) => handleTabChange(e.target.value)}
            >
              {tabs.map((tab) => (
                <option key={tab.id} value={tab.id}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>

          <div className="hidden sm:block">
            <nav className="flex space-x-4" aria-label="Tabs">
              {tabs.map((tab) => {
                const Icon = tab.icon;
                return (
                  <button
                    key={tab.id}
                    onClick={() => handleTabChange(tab.id)}
                    className={cn(
                      'px-3 py-2 font-medium text-sm rounded-md flex items-center space-x-2',
                      activeTab === tab.id
                        ? 'bg-indigo-100 text-indigo-700'
                        : 'text-gray-500 hover:text-gray-700'
                    )}
                  >
                    <Icon className="h-5 w-5" />
                    <span>{tab.name}</span>
                  </button>
                );
              })}
            </nav>
          </div>

          <div className="mt-8">
            {renderTabContent()}
          </div>
        </div>
      </div>
    </div>
  );
}