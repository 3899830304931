import React, { useState, useEffect } from 'react';
import { Bell, Save } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { NotificationPreferences, NotificationChannel, NotificationFrequency } from '../../types/notifications';
import { queryByIndex, createOrUpdate } from '../../lib/db';
import { cn } from '../../lib/utils';

const channels: { id: NotificationChannel; name: string }[] = [
  { id: 'dashboard', name: 'Dashboard' },
  { id: 'email', name: 'Email' },
  { id: 'sms', name: 'SMS' },
];

const frequencies: { id: NotificationFrequency; name: string }[] = [
  { id: 'realtime', name: 'Real-time' },
  { id: 'daily', name: 'Daily Digest' },
  { id: 'weekly', name: 'Weekly Digest' },
];

export default function NotificationSettings() {
  const { user } = useAuth();
  const [preferences, setPreferences] = useState<NotificationPreferences | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    loadPreferences();
  }, [user]);

  const loadPreferences = async () => {
    if (!user?.id) return;

    try {
      const userPrefs = await queryByIndex<NotificationPreferences>(
        'notificationPreferences',
        'by-user',
        user.id
      );

      if (userPrefs.length > 0) {
        setPreferences(userPrefs[0]);
      } else {
        // Create default preferences
        const defaultPrefs: NotificationPreferences = {
          id: crypto.randomUUID(),
          userId: user.id,
          enabledTypes: ['system', 'campaign'],
          channels: ['dashboard'],
          frequency: 'realtime',
          updatedAt: new Date(),
        };
        await createOrUpdate('notificationPreferences', defaultPrefs);
        setPreferences(defaultPrefs);
      }
    } catch (error) {
      console.error('Error loading notification preferences:', error);
    }
  };

  const handleSave = async () => {
    if (!preferences) return;

    setIsSaving(true);
    try {
      const updatedPreferences = {
        ...preferences,
        updatedAt: new Date(),
      };
      await createOrUpdate('notificationPreferences', updatedPreferences);
      setPreferences(updatedPreferences);
    } catch (error) {
      console.error('Error saving notification preferences:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const toggleType = (type: 'system' | 'campaign') => {
    if (!preferences) return;

    setPreferences(prev => {
      if (!prev) return prev;

      const enabledTypes = prev.enabledTypes.includes(type)
        ? prev.enabledTypes.filter(t => t !== type)
        : [...prev.enabledTypes, type];

      return { ...prev, enabledTypes };
    });
  };

  const toggleChannel = (channel: NotificationChannel) => {
    if (!preferences) return;

    setPreferences(prev => {
      if (!prev) return prev;

      const channels = prev.channels.includes(channel)
        ? prev.channels.filter(c => c !== channel)
        : [...prev.channels, channel];

      return { ...prev, channels };
    });
  };

  const setFrequency = (frequency: NotificationFrequency) => {
    if (!preferences) return;
    setPreferences(prev => prev ? { ...prev, frequency } : prev);
  };

  if (!preferences) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
      </div>
    );
  }

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Notification Preferences
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Choose how and when you want to receive notifications
            </p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="space-y-6">
              <div>
                <h4 className="text-sm font-medium text-gray-900">Notification Types</h4>
                <div className="mt-2 space-y-2">
                  <div className="flex items-center">
                    <button
                      onClick={() => toggleType('system')}
                      className={cn(
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        preferences.enabledTypes.includes('system') ? 'bg-indigo-600' : 'bg-gray-200'
                      )}
                    >
                      <span className={cn(
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                        preferences.enabledTypes.includes('system') ? 'translate-x-5' : 'translate-x-0'
                      )} />
                    </button>
                    <span className="ml-3 text-sm text-gray-900">System Notifications</span>
                  </div>
                  <div className="flex items-center">
                    <button
                      onClick={() => toggleType('campaign')}
                      className={cn(
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        preferences.enabledTypes.includes('campaign') ? 'bg-indigo-600' : 'bg-gray-200'
                      )}
                    >
                      <span className={cn(
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                        preferences.enabledTypes.includes('campaign') ? 'translate-x-5' : 'translate-x-0'
                      )} />
                    </button>
                    <span className="ml-3 text-sm text-gray-900">Campaign Alerts</span>
                  </div>
                </div>
              </div>

              <div>
                <h4 className="text-sm font-medium text-gray-900">Delivery Channels</h4>
                <div className="mt-2 flex flex-wrap gap-2">
                  {channels.map((channel) => (
                    <button
                      key={channel.id}
                      onClick={() => toggleChannel(channel.id)}
                      className={cn(
                        'inline-flex items-center px-3 py-1 rounded-full text-sm font-medium',
                        preferences.channels.includes(channel.id)
                          ? 'bg-indigo-100 text-indigo-800'
                          : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                      )}
                    >
                      {channel.name}
                    </button>
                  ))}
                </div>
              </div>

              <div>
                <h4 className="text-sm font-medium text-gray-900">Notification Frequency</h4>
                <div className="mt-2">
                  <div className="space-y-2">
                    {frequencies.map((freq) => (
                      <div key={freq.id} className="flex items-center">
                        <input
                          id={freq.id}
                          name="frequency"
                          type="radio"
                          checked={preferences.frequency === freq.id}
                          onChange={() => setFrequency(freq.id)}
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                        />
                        <label htmlFor={freq.id} className="ml-3 text-sm text-gray-700">
                          {freq.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <button
                type="button"
                onClick={handleSave}
                disabled={isSaving}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {isSaving ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                    Saving...
                  </>
                ) : (
                  <>
                    <Save className="h-4 w-4 mr-2" />
                    Save Preferences
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}