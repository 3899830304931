import React, { useState } from 'react';
import { Plus, Filter, Calendar, Save } from 'lucide-react';
import ReportBuilder from './ReportBuilder';

interface CustomReportsProps {
  onSelectReport: (reportId: string) => void;
}

export default function CustomReports({ onSelectReport }: CustomReportsProps) {
  const [showBuilder, setShowBuilder] = useState(false);
  const [savedReports] = useState([
    {
      id: 'custom-1',
      name: 'Cross-Channel ROI Analysis',
      description: 'Weekly performance analysis across all marketing channels',
      lastRun: '2024-03-15T10:00:00Z',
      schedule: 'Weekly',
    },
    {
      id: 'custom-2',
      name: 'Campaign Conversion Tracking',
      description: 'Daily conversion and attribution tracking for active campaigns',
      lastRun: '2024-03-16T09:30:00Z',
      schedule: 'Daily',
    },
  ]);

  if (showBuilder) {
    return <ReportBuilder onClose={() => setShowBuilder(false)} />;
  }

  return (
    <div className="space-y-6 mt-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-gray-900">Saved Reports</h3>
        <button
          onClick={() => setShowBuilder(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          Create New Report
        </button>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {savedReports.map((report) => (
            <li key={report.id}>
              <div className="px-4 py-4 sm:px-6 hover:bg-gray-50">
                <div className="flex items-center justify-between">
                  <div className="flex-1 min-w-0">
                    <button
                      onClick={() => onSelectReport(report.id)}
                      className="text-left block focus:outline-none"
                    >
                      <p className="text-sm font-medium text-indigo-600 truncate">
                        {report.name}
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        {report.description}
                      </p>
                    </button>
                  </div>
                  <div className="flex items-center space-x-4">
                    <div className="flex items-center text-sm text-gray-500">
                      <Calendar className="h-4 w-4 mr-1" />
                      {report.schedule}
                    </div>
                    <button
                      onClick={() => onSelectReport(report.id)}
                      className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-indigo-100 hover:bg-indigo-200"
                    >
                      View Report
                    </button>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}