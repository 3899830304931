import React, { useState, useEffect } from 'react';
import { UserPlus } from 'lucide-react';
import { TeamMember } from '../../types/company';
import { useAuth } from '../../contexts/AuthContext';
import { queryByIndex } from '../../lib/db';
import InviteTeamMemberModal from '../team/InviteTeamMemberModal';
import TeamMemberTable from '../team/TeamMemberTable';

export default function TeamSettings() {
  const { user } = useAuth();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showInviteModal, setShowInviteModal] = useState(false);

  useEffect(() => {
    loadTeamMembers();
  }, [user]);

  const loadTeamMembers = async () => {
    if (!user?.organizationId) return;
    
    try {
      const members = await queryByIndex<TeamMember>(
        'teamMembers',
        'by-company',
        user.organizationId
      );
      setTeamMembers(members);
    } catch (error) {
      console.error('Error loading team members:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInviteSent = async (newMember: TeamMember) => {
    setTeamMembers(prev => [...prev, newMember]);
    setShowInviteModal(false);
  };

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Team Members
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Manage your team members and their access levels
            </p>
          </div>
          <div className="mt-4 sm:mt-0">
            <button
              type="button"
              onClick={() => setShowInviteModal(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <UserPlus className="h-4 w-4 mr-2" />
              Invite Team Member
            </button>
          </div>
        </div>

        <div className="mt-6">
          <TeamMemberTable
            members={teamMembers}
            isLoading={isLoading}
            onMemberUpdate={loadTeamMembers}
          />
        </div>
      </div>

      {showInviteModal && (
        <InviteTeamMemberModal
          onClose={() => setShowInviteModal(false)}
          onInviteSent={handleInviteSent}
        />
      )}
    </div>
  );
}