import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Notification } from '../types/notifications';
import { read, update, queryByIndex } from '../lib/db';
import { useAuth } from '../contexts/AuthContext';

export function useNotifications() {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const { data: notifications = [] } = useQuery({
    queryKey: ['notifications', user?.id],
    queryFn: async () => {
      if (!user?.id) return [];
      return queryByIndex<Notification>('notifications', 'by-user', user.id);
    },
    select: (data) => data.sort((a, b) => 
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    ),
  });

  const markAsRead = useMutation({
    mutationFn: async (notificationId: string) => {
      const notification = await read<Notification>('notifications', notificationId);
      if (notification) {
        return update('notifications', notificationId, {
          ...notification,
          read: true,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });

  return {
    notifications,
    markAsRead: markAsRead.mutate,
    isLoading: markAsRead.isPending,
  };
}