import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import AuthTabs from './AuthTabs';
import { useAuth } from '../../contexts/AuthContext';

export default function AuthPage() {
  const [activeTab, setActiveTab] = useState<'login' | 'register'>('login');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = (token: string, user: any) => {
    login(token, user);
    navigate('/dashboard');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Welcome to Marketing Platform
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {activeTab === 'login' ? 'Sign in to your account' : 'Create your account'}
          </p>
        </div>

        <AuthTabs activeTab={activeTab} onTabChange={setActiveTab} />

        {activeTab === 'login' ? (
          <LoginForm onSuccess={handleLogin} />
        ) : (
          <RegisterForm onSuccess={handleLogin} />
        )}
      </div>
    </div>
  );
}