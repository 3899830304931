import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { sankey, sankeyLinkHorizontal } from 'd3-sankey';
import { DateRange } from '../../../types/common';

interface AttributionChartProps {
  dateRange: DateRange;
}

interface SankeyNode {
  id: string;
  name: string;
  value?: number;
  x0?: number;
  x1?: number;
  y0?: number;
  y1?: number;
}

interface SankeyLink {
  source: string | SankeyNode;
  target: string | SankeyNode;
  value: number;
}

interface SankeyData {
  nodes: SankeyNode[];
  links: SankeyLink[];
}

export default function AttributionChart({ dateRange }: AttributionChartProps) {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    // Sample data - replace with actual data
    const data: SankeyData = {
      nodes: [
        { id: 'google', name: 'Google Ads' },
        { id: 'meta', name: 'Meta Ads' },
        { id: 'tiktok', name: 'TikTok Ads' },
        { id: 'view', name: 'View' },
        { id: 'click', name: 'Click' },
        { id: 'conversion', name: 'Conversion' }
      ],
      links: [
        { source: 'google', target: 'view', value: 2000 },
        { source: 'meta', target: 'view', value: 1500 },
        { source: 'tiktok', target: 'view', value: 1000 },
        { source: 'view', target: 'click', value: 800 },
        { source: 'click', target: 'conversion', value: 200 }
      ]
    };

    const width = svgRef.current.clientWidth;
    const height = 300;

    // Clear existing content
    d3.select(svgRef.current).selectAll('*').remove();

    const sankeyGenerator = sankey<SankeyData, SankeyNode, SankeyLink>()
      .nodeId((d: SankeyNode) => d.id)
      .nodeWidth(15)
      .nodePadding(10)
      .extent([[1, 1], [width - 1, height - 6]]);

    const { nodes, links } = sankeyGenerator(data);

    const svg = d3.select(svgRef.current);

    // Add links
    svg.append('g')
      .selectAll('path')
      .data(links)
      .join('path')
      .attr('d', sankeyLinkHorizontal())
      .attr('stroke', '#cbd5e1')
      .attr('stroke-width', (d: any) => Math.max(1, d.width))
      .attr('fill', 'none')
      .attr('opacity', 0.5);

    // Add nodes
    svg.append('g')
      .selectAll('rect')
      .data(nodes)
      .join('rect')
      .attr('x', (d: any) => d.x0)
      .attr('y', (d: any) => d.y0)
      .attr('height', (d: any) => d.y1 - d.y0)
      .attr('width', (d: any) => d.x1 - d.x0)
      .attr('fill', '#3b82f6');

    // Add labels
    svg.append('g')
      .selectAll('text')
      .data(nodes)
      .join('text')
      .attr('x', (d: any) => (d.x0! < width / 2 ? d.x1! + 6 : d.x0! - 6))
      .attr('y', (d: any) => (d.y1! + d.y0!) / 2)
      .attr('dy', '0.35em')
      .attr('text-anchor', (d: any) => (d.x0! < width / 2 ? 'start' : 'end'))
      .text((d: any) => `${d.name} (${d.value})`)
      .attr('font-size', '12px')
      .attr('fill', '#4b5563');

  }, [dateRange]);

  return (
    <div className="w-full h-[300px]">
      <svg ref={svgRef} width="100%" height="300" />
    </div>
  );
}