import React from 'react';
import { Bell, AlertCircle, Megaphone } from 'lucide-react';
import { Notification } from '../../types/notifications';
import { formatRelativeTime } from '../../lib/utils';
import { cn } from '../../lib/utils';

interface NotificationListProps {
  notifications: Notification[];
  onMarkAsRead: (id: string) => void;
}

export default function NotificationList({ notifications, onMarkAsRead }: NotificationListProps) {
  const getIcon = (type: Notification['type']) => {
    switch (type) {
      case 'system':
        return Bell;
      case 'campaign':
        return Megaphone;
      default:
        return AlertCircle;
    }
  };

  const getPriorityColor = (priority: Notification['priority']) => {
    switch (priority) {
      case 'high':
        return 'text-red-600 bg-red-100';
      case 'medium':
        return 'text-yellow-600 bg-yellow-100';
      case 'low':
        return 'text-green-600 bg-green-100';
    }
  };

  if (notifications.length === 0) {
    return (
      <div className="text-center py-12">
        <Bell className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">No notifications</h3>
        <p className="mt-1 text-sm text-gray-500">You're all caught up!</p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {notifications.map((notification) => {
          const Icon = getIcon(notification.type);
          const priorityColor = getPriorityColor(notification.priority);

          return (
            <li
              key={notification.id}
              className={cn(
                'px-4 py-4',
                !notification.read && 'bg-blue-50'
              )}
            >
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <Icon className="h-6 w-6 text-gray-400" />
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900">
                      {notification.title}
                    </p>
                    <div className="ml-2 flex-shrink-0 flex">
                      <span className={cn(
                        'px-2 inline-flex text-xs leading-5 font-semibold rounded-full',
                        priorityColor
                      )}>
                        {notification.priority}
                      </span>
                    </div>
                  </div>
                  <p className="mt-1 text-sm text-gray-500">
                    {notification.message}
                  </p>
                  <div className="mt-2 flex items-center justify-between">
                    <p className="text-sm text-gray-500">
                      {formatRelativeTime(notification.createdAt)}
                    </p>
                    {!notification.read && (
                      <button
                        onClick={() => onMarkAsRead(notification.id)}
                        className="text-sm text-indigo-600 hover:text-indigo-900"
                      >
                        Mark as read
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}