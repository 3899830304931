import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { CrmIntegration, CrmPlatform } from '../../types/crm';
import { queryByIndex, createOrUpdate } from '../../lib/db';
import CrmIntegrationCard from '../crm/CrmIntegrationCard';
import { CrmService } from '../../services/crm/crmService';

const SUPPORTED_PLATFORMS: CrmPlatform[] = [
  'salesforce',
  'hubspot',
  'zoho',
  'dynamics365',
  'pipedrive',
  'freshsales',
  'sugarcrm',
  'activecampaign',
  'insightly',
  'keap',
];

export default function CrmSettings() {
  const { user } = useAuth();
  const [integrations, setIntegrations] = useState<CrmIntegration[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadIntegrations();
  }, [user]);

  const loadIntegrations = async () => {
    if (!user?.organizationId) return;

    try {
      const existingIntegrations = await queryByIndex<CrmIntegration>(
        'crmIntegrations',
        'by-organization',
        user.organizationId
      );

      // Create default integration objects for platforms that don't have one
      const allIntegrations = SUPPORTED_PLATFORMS.map(platform => {
        const existing = existingIntegrations.find(i => i.platform === platform);
        if (existing) return existing;

        return {
          id: crypto.randomUUID(),
          platform,
          userId: user.id,
          organizationId: user.organizationId,
          status: 'disconnected' as const,
          syncFrequency: 'daily' as const,
          syncDirection: 'two-way' as const,
          fieldMapping: {},
          credentials: {},
          createdAt: new Date(),
          updatedAt: new Date(),
        };
      });

      setIntegrations(allIntegrations);
    } catch (error) {
      console.error('Error loading CRM integrations:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnect = async (integration: CrmIntegration) => {
    try {
      const crmService = new CrmService(integration.platform);
      await crmService.initiateOAuth();
    } catch (error) {
      console.error('Error connecting to CRM:', error);
    }
  };

  const handleDisconnect = async (integration: CrmIntegration) => {
    try {
      const updatedIntegration = {
        ...integration,
        status: 'disconnected' as const,
        credentials: {},
        error: undefined,
        updatedAt: new Date(),
      };

      await createOrUpdate('crmIntegrations', updatedIntegration);
      setIntegrations(prev =>
        prev.map(i => (i.id === integration.id ? updatedIntegration : i))
      );
    } catch (error) {
      console.error('Error disconnecting CRM:', error);
    }
  };

  const handleTest = async (integration: CrmIntegration) => {
    try {
      const crmService = new CrmService(integration.platform);
      const isValid = await crmService.testConnection(integration.credentials);

      const updatedIntegration = {
        ...integration,
        status: isValid ? 'connected' : 'error',
        error: isValid ? undefined : 'Connection test failed',
        updatedAt: new Date(),
      };

      await createOrUpdate('crmIntegrations', updatedIntegration);
      setIntegrations(prev =>
        prev.map(i => (i.id === integration.id ? updatedIntegration : i))
      );
    } catch (error) {
      console.error('Error testing CRM connection:', error);
    }
  };

  const handleConfigure = async (integration: CrmIntegration) => {
    // TODO: Implement configuration modal/page
    console.log('Configure integration:', integration);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          CRM Integrations
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Connect and manage your CRM platforms
        </p>
      </div>

      <div className="grid gap-6 lg:grid-cols-2">
        {integrations.map((integration) => (
          <CrmIntegrationCard
            key={integration.id}
            integration={integration}
            onConnect={() => handleConnect(integration)}
            onDisconnect={() => handleDisconnect(integration)}
            onTest={() => handleTest(integration)}
            onConfigure={() => handleConfigure(integration)}
          />
        ))}
      </div>
    </div>
  );
}